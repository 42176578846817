import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import AssignmentIcon from '@mui/icons-material/Assignment';
import { Collapse, Divider, List } from '@mui/material';
import { BackupTable, Engineering, ExpandLess, ExpandMore, Factory, GavelOutlined, Group, HowToReg, LocalShipping, Payments, Receipt, Route, StoreMallDirectory, Widgets } from '@mui/icons-material';

function MainListItems() {
    const [open1, setOpen1] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [open3, setOpen3] = React.useState(false);
    const [open4, setOpen4] = React.useState(false);

    const handleClick1 = () => setOpen1(!open1);
    const handleClick2 = () => setOpen2(!open2);
    const handleClick3 = () => setOpen3(!open3);
    const handleClick4 = () => setOpen4(!open4);

    return (
        <React.Fragment>
            <ListItemButton>
                <ListItemIcon>
                    <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
            </ListItemButton>
            <Divider sx={{ my: 1 }} />
            <ListSubheader component="div" inset>
                Catálogos
            </ListSubheader>
            {/* Transportistas */}
            <ListItemButton onClick={handleClick1}>
                <ListItemIcon>
                    <Engineering />
                </ListItemIcon>
                <ListItemText primary="Transportistas" />
                {open1 ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open1} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                            <Engineering />
                        </ListItemIcon>
                        <ListItemText primary="Operadores" />
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                            <LocalShipping />
                        </ListItemIcon>
                        <ListItemText primary="Camiones" />
                    </ListItemButton>
                </List>
            </Collapse>
            {/* Clientes */}
            <ListItemButton onClick={handleClick2}>
                <ListItemIcon>
                    <Group />
                </ListItemIcon>
                <ListItemText primary="Clientes" />
                {open2 ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open2} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                            <StoreMallDirectory />
                        </ListItemIcon>
                        <ListItemText primary="Almacenes (Tiro)" />
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                            <Route />
                        </ListItemIcon>
                        <ListItemText primary="Rutas" />
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                            <Factory />
                        </ListItemIcon>
                        <ListItemText primary="Orígenes" />
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                            <Widgets />
                        </ListItemIcon>
                        <ListItemText primary="Materiales" />
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                            <BackupTable />
                        </ListItemIcon>
                        <ListItemText primary="Paquetes de trabajo" />
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                            <HowToReg />
                        </ListItemIcon>
                        <ListItemText primary="Checadores" />
                    </ListItemButton>
                </List>
            </Collapse>

            {/* Operaciones */}
            <Divider sx={{ my: 1 }} />
            <ListSubheader component="div" inset>
                Operaciones
            </ListSubheader>
            <ListItemButton onClick={handleClick3}>
                <ListItemIcon>
                    <GavelOutlined />
                </ListItemIcon>
                <ListItemText primary="Contratos" />
                {open3 ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open3} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                            <AssignmentIcon />
                        </ListItemIcon>
                        <ListItemText primary="Ordenes de venta" />
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                            <Route />
                        </ListItemIcon>
                        <ListItemText primary="Planeación de rutas" />
                    </ListItemButton>
                </List>
            </Collapse>

            {/* Contabilidad */}
            <Divider sx={{ my: 1 }} />
            <ListSubheader component="div" inset>
                Contabilidad
            </ListSubheader>
            <ListItemButton onClick={handleClick4}>
                <ListItemIcon>
                    <ShoppingCartIcon />
                </ListItemIcon>
                <ListItemText primary="Trabajo" />
                {open4 ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open4} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                            <AssignmentIcon />
                        </ListItemIcon>
                        <ListItemText primary="Generador" />
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                            <Route />
                        </ListItemIcon>
                        <ListItemText primary="Estimaciones" />
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                            <Route />
                        </ListItemIcon>
                        <ListItemText primary="Evidencia de rutas" />
                    </ListItemButton>
                </List>
            </Collapse>
            <ListItemButton>
                <ListItemIcon>
                    <Receipt />
                </ListItemIcon>
                <ListItemText primary="Facturación" />
            </ListItemButton>
            <ListItemButton>
                <ListItemIcon>
                    <Payments />
                </ListItemIcon>
                <ListItemText primary="Cobranza" />
            </ListItemButton>
        </React.Fragment>
    );
}

export default function Navbar() {
    return <MainListItems />;
}