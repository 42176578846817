import * as React from 'react';
import Title from './Title';


export default function Chart() {

    return (
        <React.Fragment>
            <Title>Gráficos</Title>
        </React.Fragment>
    );
}